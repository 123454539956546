import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements AfterViewInit {
  @ViewChild('theCanvas', { static: false })
  theCanvas: ElementRef<HTMLCanvasElement>;
  textValue: string = ''
  loading: boolean = false
  imageUrl: any
  croppedImage: any;

  @Input() page: any;

  constructor() {
    console.log('constructor')
  }


  ngAfterViewInit() {
    var scale = 2;
    var viewport = this.page.getViewport(scale);

    //
    // Prepare canvas using PDF page dimensions
    //
    let canvas = this.theCanvas.nativeElement
    var context = canvas.getContext('2d');
    context.imageSmoothingEnabled = false;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    //
    // Render PDF page into canvas context
    //

    var task = this.page.render({ canvasContext: context, viewport: viewport })
    let comp = this
    task.promise.then(function () {
      comp.imageUrl = canvas.toDataURL('image/png')
      console.log('success')
      //AppComponent.ocr(canvas.toDataURL('image/jpeg'))
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
  }

  public loadOcr() {
    this.loading = true
    AppComponent.ocr(this.croppedImage).then(text => {
      this.loading = false
      this.textValue = text
    })
  }

}
