import { Component, ViewChild, ElementRef } from '@angular/core';
import { createWorker } from 'tesseract.js';
import * as pdfjs from 'pdfjs-dist';
import * as pdfWorker  from 'pdfjs-dist/build/pdf.worker.entry'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;
  public pages: any[] = []
  public data: object;
  private pdf_doc: any
  private start: number = 1;
  private end: number = 10;
  public active: boolean = false;
  constructor() {
  }
  public static async ocr(file) {
    const worker = createWorker({
      logger: m => console.log(m),
    });
    await worker.load();
    await worker.loadLanguage('aze');
    await worker.initialize('aze');
    const { data: { text } } = await worker.recognize(file);

    await worker.terminate();
    return text
  }

  private renderCover(page) {
    var scale = 0.5;
    var viewport = page.getViewport(scale);
    let canvas = this.canvas.nativeElement;
    var context = canvas.getContext('2d');;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    var task = page.render({ canvasContext: context, viewport: viewport })
  }

  public async handleFileInput(files) {
    console.log('called')
    pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
    this.pdf_doc = await pdfjs.getDocument(URL.createObjectURL(files[0]))
    this.data = {
      name: '',
      date: '',
      author: ''
    }
    this.renderCover(await this.pdf_doc.getPage(1))
    this.loadPage(2)
    this.active = true

  }

  public showAll() {
    this.start = 1
    this.end = 10
    this.pages = []
    this.load()
  }

  public loadPage(pageNumber) {
    console.log(pageNumber)
    this.pdf_doc.getPage(parseInt(pageNumber)).then(page => {
      this.pages = [page]
    })
  }

  public loadMore() {
    this.start += 10
    this.end += 10
    this.load()
  }

  private async load() {
    for (let i = this.start; i <= this.end; i++) {
      let page = await this.pdf_doc.getPage(i)
      this.pages.push(page)
    }
  }
}
